<mc-layout-content>

	<h1>{{ (race$ | async).model.trackName }}</h1>
	<p class="section">
		{{ (race$ | async).model.date | date:'d MMMM YYYY (EEEE)' }}
	</p>

	<div class="section fullscreen">
		<mat-divider class=""></mat-divider>
	</div>

	<h2>Primary Rankings</h2>

	<div class="section fullscreen">
		<a
			*ngFor="let ranking of primaryRankings$ | async"
			class="tile-link side-paddings"
			[routerLink]="['rankings', ranking.model.id]"
			routerLinkActive="active"
		>
      <span>{{ ranking.model.name }}</span>
      <span>{{ ranking.model.description }}</span>
    </a>
	</div>

	<h2>Side Rankings</h2>

	<div class="section fullscreen">
		<a
			*ngFor="let ranking of sideRankings$ | async"
			class="tile-link side-paddings"
			[routerLink]="['rankings', ranking.model.id]"
			routerLinkActive="active"
    >
      {{ ranking.model.name }}
      <small>{{ ranking.model.description }}</small>
    </a>
	</div>

	<h2>Rules</h2>

	<div class="section fullscreen rules">
		<mat-tab-group dynamicHeight>

			<mat-tab label="Settings">
				<div class="side-paddings">
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Shortest valid lap time</th>
								<td>
									{{ (race$ | async).model.resultsConfig.minimumLapMicrotime * 1000 * 1000 | laptimeFilter:'minutes' }}
								</td>
							</tr>
							<tr>
								<th>Longest valid lap time</th>
								<td>
									{{ (race$ | async).model.resultsConfig.maximumLapMicrotime * 1000 * 1000 | laptimeFilter:'minutes'}}
								</td>
							</tr>
							<tr>
								<th>Season points multiplier</th>
								<td>x{{ (race$ | async).model.pointsMultiplier }}</td>
							</tr>
							<tr>
								<th>Registered drivers</th>
								<td>{{ (drivers$ | async).length }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</mat-tab>

			<mat-tab label="Points">
				<div class="side-paddings">
					<table>
						<thead>
							<tr>
								<th>Position</th>
								<th>Season points</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let points of SEASON_POINTS_MAP; let index = index">
								<th>{{ index + 1 }}.</th>
								<td>{{ ((race$ | async).model.pointsMultiplier) * points }}</td>
							</tr>
							<tr>
								<th>...</th>
								<td>{{ SEASON_POINTS_MAP.slice(-1) }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</mat-tab>

			<mat-tab label="Drivers">
				<div class="side-paddings">
					<table>
						<thead>
							<tr>
								<th>Number</th>
								<th>Name</th>
								<th>RFID tag</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let driver of drivers$ | async">
								<th>{{ driver.model.startNumber }}</th>
								<td>{{ driver.model.name }}</td>
								<td>{{ driver.model.epc }}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<th colspan="2">Total</th>
								<td>{{ (drivers$ | async).length }}</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</mat-tab>

		</mat-tab-group>
	</div>

</mc-layout-content>
