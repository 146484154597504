import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { LayoutContentComponent } from './layout-content/layout-content.component';
import { RoutingSlotComponent } from './routing-slot/routing-slot.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BreadcrumbLabelPipe } from './breadcrumbs/breadcrumb-label.pipe';
import { BreadcrumbsService } from './breadcrumbs/breadcrumbs.service';

@NgModule({
  declarations: [
    LayoutContentComponent,
    RoutingSlotComponent,
    BreadcrumbsComponent,
    BreadcrumbLabelPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule
  ],
  exports: [
    LayoutContentComponent,
    BreadcrumbsComponent,
    BreadcrumbLabelPipe
  ],
  providers: [
    BreadcrumbLabelPipe,
    BreadcrumbsService,
  ]
})
export class LayoutModule { }
