<table class="table" *ngIf="standings">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Number</th>
    <th scope="col">Driver</th>
    <th scope="col">Average</th>
    <th scope="col">Ranking results</th>
    <th scope="col">All results</th>
    <th scope="col" class="season-points">Season pts</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let standing of standings$ | async">
    <td scope="row" class="position">{{standing.position}}.</td>
    <th class="start-number" title="RFID: {{standing.driver.epc}}">
      {{standing.driver.startNumber}}
    </th>
    <td>{{standing.driver.name}}{{standing.driver.category}}</td>
    <td class="average" [class.complete]="standing.rankingResults?.length >= resultsConfig.rankingLapsNumber">
      Ø{{standing.rankingResultsAverage | laptimeFilter:'minutes'}}
    </td>
    <td class="ranking-results">
      <span *ngFor="let result of standing.rankingResults">
        <mc-result [result]="result" (changed)="recalculate()"></mc-result>
      </span>
    </td>
    <td class="all-results">
      <span
        *ngFor="let result of standing.results"
        class="result-wrapper"
        [class.out-of-range]="!isInRange(result.microtime)"
      >
        <mc-result [result]="result" (changed)="recalculate()"></mc-result>
      </span>
    </td>
    <td>
			{{standing.seasonPoints}}
      <!--{{standing.results.length > 0 ? (standing.position | seasonPoints) : 0}}-->
    </td>
  </tr>
  </tbody>
</table>
