<form novalidate>
	<label>
		<input type="checkbox" (change)="showCsvExport = $event.target.checked" />
		{{tags.length}} tags.
	</label>

	<div *ngIf="showCsvExport">
		<hr />
		<div *ngFor="let tag of tags">
			#{{tag.Id}} <b>{{tag.Nr_startowy}}</b>, {{tag.EPC}}
		</div>
	</div>
</form>
