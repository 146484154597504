import { Injectable } from '@angular/core';
import { Standing } from '../standing';
import { GateReading } from '../../gepard/gate-readings/gate-reading';
import { ResultsService } from '../results/results.service';
import { Result } from '../results/result/result';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { TagsService } from '../../gepard/tags/tags.service';
import { ResultsFilterPipe } from '../results/results-config/results-filter.pipe';
import { ResultsConfig } from '../results/results-config/results-config';

@Injectable({
  providedIn: 'root'
})
export class StandingsService {

  readonly RFID_ACCURACY_THRESHOLD = 2 * 1000 * 1000;

  standings: Standing[] = [];

  constructor(
    private driversService: DriversService,
    private tagsService: TagsService,
    private resultsFilter: ResultsFilterPipe
  ) {
  }

  findByEpc(epc: string): Standing {
    return this.standings.find(standing => standing.driver && standing.driver.epc === epc);
  }

  findByStartNumber(startNumber: number): Standing {
    return this.standings.find(standing => standing.driver && standing.driver.startNumber === startNumber);
  }

  fromResults(results: Result[]): Standing[] {
    const standingsByStartNumber = {};
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      const driver = this.driversService.findByStartNumber(result.driverId);
      if (driver) {
        if (!standingsByStartNumber[result.driverId]) {
          standingsByStartNumber[result.driverId] = {
            driver: driver,
            tag: this.tagsService.findByEpc(driver.epc), // TODO fix it
            results: []
          } as Standing;
        }
        standingsByStartNumber[result.driverId].results.push(result);
      }
    }
    return Object.values<Standing>(standingsByStartNumber).sort(this.compareStandingsByStartNumber);
  }

  getRecalculated(standing: Standing, resultsConfig: ResultsConfig): Standing {
    const rankingResults = this.extractRankingResults(standing.results, resultsConfig);
    return {
      ...standing,
      ... {
        rankingResults: rankingResults,
        rankingResultsAverage: this.average(rankingResults.map(result => result.microtime))
      }
    };
  }

  protected extractRankingResults(results: Result[], resultsConfig: ResultsConfig): Result[] {
    return this.resultsFilter.transform(results, resultsConfig)
      .sort((a, b) => a.microtime - b.microtime)
      .slice(0, resultsConfig.rankingLapsNumber);
  }

  protected average(results, n = 3) {
    let i;
    let sum = 0;
    for (i = 0; i < results.length && (n <= 0 || i < n); i++) {
      sum += results[i];
    }
    return i ? (sum / i) : null;
  }

  fromGateReadings(gateReadings: GateReading[], minLapSeconds?: number, maxLapSeconds?: number): Standing[] {
    const standingsByEpc = {};
    const lastLapStartsByEpc = {};
    const lapsCountsByEpc = {};
    const minLapDuration = (minLapSeconds || 80) * 1000 * 1000;
    const maxLapDuration = (maxLapSeconds || 300) * 1000 * 1000;

    for (let i = 0; i < gateReadings.length; i++) {
      const gateReading = gateReadings[i];
      const driver = this.driversService.findByEpc(gateReading.tag_code);
      if (driver) {
        if (!standingsByEpc[gateReading.tag_code]) {
          standingsByEpc[gateReading.tag_code] = {
            driver: driver,
            tag: this.tagsService.findByEpc(gateReading.tag_code),
            results: []
          } as Standing;
        }
        const standing = standingsByEpc[gateReading.tag_code];

        if (gateReading.tag_code in lastLapStartsByEpc) {
          const diff = (new Date(gateReading.first_detection_date).getTime() - new Date(lastLapStartsByEpc[gateReading.tag_code].first_detection_date).getTime()) * 1000;
          if (diff <= this.RFID_ACCURACY_THRESHOLD) {
            // Ignores gate reading.
          } else {
            if (diff >= minLapDuration && diff <= maxLapDuration) {
              if (gateReading.tag_code in lapsCountsByEpc) {
                lapsCountsByEpc[gateReading.tag_code]++;
              } else {
                lapsCountsByEpc[gateReading.tag_code] = 0;
              }
              const id = gateReading.gr_id;
              const oldResult = standing.results.find(result => result.id === id);
              const newResult = {
                _id: ResultsService.toRemoteId(id),
                id: id,
                driverId: standing.driver.startNumber,
                microtime: diff,
                createdAt: lastLapStartsByEpc[gateReading.tag_code].first_detection_date,
                correction: 0,
                verification: null
              } as Result;
              if (!oldResult) {
                standing.results.push(newResult);
                // console.log('Result #' + newResult.id + ' added.');
              } else if (!ResultsService.areEqual(oldResult, newResult)) {
                standing.results[standing.results.indexOf(oldResult)] = {...oldResult, ...newResult};
                // console.log('Result #' + newResult.id + ' updated.');
              } else {
                // console.log('Result #' + newResult.id + ' not changed.');
              }
            }
            lastLapStartsByEpc[gateReading.tag_code] = gateReading;
          }
        } else if (standing) {
          lastLapStartsByEpc[gateReading.tag_code] = gateReading;
        }
      }
    }
    return Object.values<Standing>(standingsByEpc).sort(this.compareStandingsByEpc);
  }

  private compareStandingsByEpc(a: Standing, b: Standing): number {
    if (a.driver.epc > b.driver.epc) {
      return 1;
    } else if (a.driver.epc < b.driver.epc) {
      return -1;
    } else {
      return 0;
    }
  }

  private compareStandingsByStartNumber(a: Standing, b: Standing): number {
    return a.driver.startNumber - b.driver.startNumber;
  }
}
