import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { mergeMap, switchMap, tap, map, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { LayoutService } from '../../layout/layout.service';
import { BreadcrumbsService } from '../../layout/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from '../../layout/breadcrumbs/breadcrumb';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Component({
  selector: 'mc-races',
  templateUrl: './seasons-layout.component.html',
  styleUrls: ['./seasons-layout.component.scss']
})
export class SeasonsLayoutComponent {

  @ViewChild('primaryMenu', {read: MatSidenav}) primaryMenu: MatSidenav;

  isHandset$ = this.layoutService.isHandset$.pipe(
    tap(isHandset => {
      this.close = isHandset ? () => this.primaryMenu.close() : () => {};
    }),
    shareReplay(1)
  );

  league$ = this.activeRoute.data.pipe(
    map(data => data.league),
    shareReplay(1)
  );

  seasons$ = this.league$.pipe(
    mergeMap((league: AsyncCollectionElement): Observable<AsyncCollectionElement[]> => league.seasons$),
    shareReplay(1)
  );

  season$ = this.breadcrumbsService.state$.pipe(
    map(breadcrumb => breadcrumb.params.seasonYear),
    distinctUntilChanged((a, b) => a && b && a === b),
    switchMap(seasonYear => this.seasons$.pipe(
      map(seasons => seasons.find(season => season.model.year === +seasonYear) || null)
    )),
    shareReplay(1)
  );

  races$ = this.season$.pipe(
    mergeMap(season => season ? season.races$ : []),
    map((races: AsyncCollectionElement[]) => races),
    shareReplay(1)
  );

  race$ = this.breadcrumbsService.state$.pipe(
    map(breadcrumb => breadcrumb.params.raceId),
    distinctUntilChanged((a, b) => a && b && a === b),
    switchMap(raceId => this.races$.pipe(
      map(races => races.find(race => race.model.id === +raceId) || null),
    )),
    shareReplay(1)
  );

  rankings$ = this.season$.pipe(
    mergeMap(season => season ? season.rankings$ : []),
    map((rankings: AsyncCollectionElement[]) => rankings),
    shareReplay(1)
  );

  ranking$ = this.breadcrumbsService.state$.pipe(
    map(breadcrumb => breadcrumb.params.rankingId),
    distinctUntilChanged((a, b) => a && b && a === b),
    switchMap(rankingId => this.rankings$.pipe(
      map(rankings => rankings.find(ranking => ranking.id === +rankingId) || null)
    )),
    shareReplay(1)
  );

  parentBreadcrumb$: Observable<Breadcrumb> = this.breadcrumbsService.breadcrumbs$.pipe(
    map(breadcrumbs => breadcrumbs.slice(-2, -1)[0]),
    shareReplay(1)
  );

  secondaryMenu$: Observable<{label: string, url: any}[]> = combineLatest([
    this.season$,
    this.race$,
    this.rankings$,
    this.ranking$
  ]).pipe(
    map(([season, race, rankings, selectedRanking]) => {
      if (season && race && selectedRanking) {
        return rankings.map(ranking => ({label: ranking.name, url: ['/seasons', season.model.year, 'races', race.model.id, 'rankings', ranking.id]}));
      } else {
        return [];
      }
    })
  );

  constructor(
    private activeRoute: ActivatedRoute,
    private layoutService: LayoutService,
    public breadcrumbsService: BreadcrumbsService
  ) {
  }

  close: (() => void) = () => {};
}
