import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ResultsConfig } from '../../results/results-config/results-config';
import { Result } from '../../results/result/result';
import { SeasonPointsPipe } from '../../../seasons/season/season-points.pipe';
import { Standing } from '../../standing';
import { Race } from '../../races/race';
import { MatTable } from '@angular/material/table';
import { SortDirection } from '@angular/material/sort';

@Component({
  selector: 'mc-race-ranking-standings-list',
  templateUrl: './race-ranking-standings-list.component.html',
  styleUrls: ['./race-ranking-standings-list.component.scss']
})
export class RaceRankingStandingsListComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('raceRankingStandingsList', {read: MatTable}) tableChild: MatTable<any>;

  @Input() race: Race;
  @Input() standings: Standing[] = [];
  @Input() resultsConfig: ResultsConfig = {};

  @Output() refresh = new EventEmitter<Result>();
  @Output() save = new EventEmitter<Result>();
  @Output() remove = new EventEmitter<Result>();
  @Output() accept = new EventEmitter<Result>();
  @Output() reject = new EventEmitter<Result>();
  @Output() updated = new EventEmitter<Standing[]>();

  displayedColumns = ['position', 'start-number', 'driver', 'average', 'season-points'];

  readonly SORT_DEFAULT = {
    ACTIVE: 'position',
    DIRECTION: 'asc' as SortDirection
  };

  sortSettings = {
    active: this.SORT_DEFAULT.ACTIVE,
    direction: this.SORT_DEFAULT.DIRECTION
  };

  constructor(
    private seasonPointsFilterPipe: SeasonPointsPipe
  ) {
  }

  ngOnInit() {
    setTimeout(() => this.recalculateOrder(this.sortSettings));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.standings && changes.standings.currentValue) {
      // console.log('standings changed - recalculate order');
      this.recalculateOrder(this.sortSettings);
    }
  }

  recalculateOrder(event?) {
    let sortSettings;
    this.sortSettings =  sortSettings = event || this.sortSettings;
    if (this.tableChild) {
      const direction = sortSettings.direction === 'desc' ? -1 : 1;

      this.standings.sort((a, b) => {
        if (+a.rankingResultsAverage === 0) {
          return 1;
        } else if (+b.rankingResultsAverage === 0) {
          return -1;
        } else {
          return a.rankingResultsAverage - b.rankingResultsAverage;
        }
      });
      this.standings.forEach((standing, i) => {
        standing.position = i + 1;
        standing.seasonPoints = this.seasonPointsFilterPipe.transform(standing.position, this.race.pointsMultiplier);
      });

      switch (sortSettings.active) {
        case 'position':
        case 'average':
          this.standings.sort((a, b) => (a.position > b.position ? 1 : -1) * direction);
          break;
        case 'start-number':
          this.standings.sort((a, b) => (a.driver.startNumber > b.driver.startNumber ? 1 : -1) * direction);
          break;
        case 'driver':
          this.standings.sort((a, b) => (a.driver.name > b.driver.name ? 1 : -1) * direction);
          break;
      }
      this.tableChild.renderRows();
    }
  }

  trackResult(index: number, result: Result) {
    return result.microtime;
  }

  trackStanding(index: number, standing: Standing) {
    return standing.position;
  }

  isActionUsed(action: string) {
    return this[action].observers.length > 0;
  }

  ngOnDestroy() {
    // console.log('destroy standings');
  }
}
