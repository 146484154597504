<div style="position: sticky; top: 0; z-index: 1;">
  <mc-results-sync></mc-results-sync>
</div>

<mc-layout-content>

<!--  <h2>{{ (ranking$ | async).description }}</h2>-->

  <!--  <mc-race-ranking-standings-->
  <!--    [race]="(race$ | async)"-->
  <!--    [resultsConfig]="(race$ | async).resultsConfig"-->
  <!--    [standings]="(race$ | async).standings"-->
  <!--  ></mc-race-ranking-standings>-->

  <div
    *ngIf="(race$ | async) && (rankingResults$ | async)"
    [hidden]="loading"
    class="fullscreen"
  >
    <mc-race-ranking-standings
      [race]="(race$ | async)"
      [resultsConfig]="(race$ | async).resultsConfig"
      [standings]="(rankingResults$ | async).standings"
    ></mc-race-ranking-standings>
  </div>

  <div *ngIf="loading">Loading...</div>
</mc-layout-content>
