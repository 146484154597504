<mat-sidenav-container
	autosize="true"
	scrollable="true"
	class="layout-viewport"
>
	<mat-sidenav-content class="layout-view">
		<mat-toolbar color="accent" class="layout-primary-navigation">
			<button
				mat-icon-button
				(click)="primaryMenu.toggle()"
				class="menu-activator"
				*ngIf="(parentBreadcrumb$ | async)?.depth < 1 && (isHandset$ | async)"
			>
				<mat-icon>menu</mat-icon>
			</button>
			<button
				mat-icon-button
				[routerLink]="(parentBreadcrumb$ | async)?.url"
				class="parent-activator"
				*ngIf="(parentBreadcrumb$ | async)?.depth > 0 && (isHandset$ | async)"
			>
				<mat-icon>arrow_back</mat-icon>
			</button>
			<!--<mc-breadcrumbs></mc-breadcrumbs>-->
      {{ breadcrumbsService.state$ | async | breadcrumbLabel }}
			<span class="spacer"></span>

			<span *ngIf="(secondaryMenu$ | async)?.length > 0">

				<button mat-raised-button color="basic" [matMenuTriggerFor]="rankingsSelection" class="season-selector-activator">
					<span *ngIf="!(ranking$ | async)">Rankings</span>
					<span *ngIf="ranking$ | async"><span *ngIf="isHandset$ | async">Ranking </span>{{(ranking$ | async)?.name }}</span>
					<mat-icon>arrow_drop_down</mat-icon>
				</button>

				<mat-menu #rankingsSelection="matMenu" xPosition="before">
					<button
						mat-menu-item
						*ngFor="let ranking of secondaryMenu$ | async"
						[routerLink]="ranking.url"
						routerLinkActive="active"
						[skipLocationChange]="(isHandset$ | async)"
					>{{ ranking.label }}
					</button>
				</mat-menu>

				<!--<button mat-icon-button [matMenuTriggerFor]="menu">-->
				<!--<mat-icon>more_vert</mat-icon>-->
				<!--</button>-->
				<!--<mat-menu #menu="matMenu">-->
				<!--<button-->
					<!--mat-menu-item-->
					<!--*ngFor="let item of secondaryMenu$ | async; let first = first"-->
					<!--[routerLink]="item.url"-->
				<!--&gt;-->
				<!--<span>{{item.label}}</span>-->
				<!--</button>-->
				<!--</mat-menu>-->
			</span>
			<!--<mat-icon>more_vert</mat-icon>-->
		</mat-toolbar>

		<div class="layout-content">
			<router-outlet></router-outlet>
		</div>

		<!--<mat-toolbar class="layout-secondary-navigation">-->
		<!--<mat-icon>home</mat-icon>-->
		<!--<span class="example-spacer"></span>-->
		<!--<span>Copyrights &copy; Ellipsis</span>-->
		<!--</mat-toolbar>-->
	</mat-sidenav-content>

	<mat-sidenav
		class="layout-primary-menu"
		#primaryMenu
		[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
		[mode]="(isHandset$ | async) ? 'over' : 'side'"
		[opened]="(isHandset$ | async) === false"
		(keydown.escape)="close()"
		[disableClose]="(isHandset$ | async) ? 'false' : 'true'"
	>
		<mat-toolbar color="primary">
      <button
        mat-icon-button
        [routerLink]="(parentBreadcrumb$ | async)?.url"
        *ngIf="parentBreadcrumb$ | async"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
			<a [routerLink]="(parentBreadcrumb$ | async)?.url">
        {{ parentBreadcrumb$ | async | breadcrumbLabel }}
      </a>
      <span *ngIf="!(parentBreadcrumb$ | async)">
        Seasons
      </span>
      <span class="spacer" *ngIf="isHandset$ | async"></span>
			<button mat-icon-button (click)="close()" *ngIf="isHandset$ | async">
				<mat-icon>close</mat-icon>
			</button>

<!--			<button mat-raised-button color="basic" [matMenuTriggerFor]="seasonSelection" class="season-selector-activator">-->
<!--				<span *ngIf="!(season$ | async)">Seasons</span>-->
<!--				<span *ngIf="season$ | async"><span *ngIf="isHandset$ | async">Season </span>{{(season$ | async)?.model.year }}</span>-->
<!--				<mat-icon>arrow_drop_down</mat-icon>-->
<!--			</button>-->

<!--			<mat-menu #seasonSelection="matMenu" xPosition="before">-->
<!--				<button-->
<!--					mat-menu-item-->
<!--					*ngFor="let season of (seasons$ | async)?.slice().reverse()"-->
<!--					[routerLink]="['/seasons', season.model.year]"-->
<!--					routerLinkActive="active"-->
<!--				>{{ season.model.year }}-->
<!--				</button>-->
<!--				<button-->
<!--					mat-menu-item-->
<!--					*ngIf="season$ | async"-->
<!--					routerLink="/"-->
<!--					routerLinkActive="active"-->
<!--					(click)="close()"-->
<!--				>-->
<!--					<mat-icon style="font-size: 1.5em; margin-right: 0.2em;">home</mat-icon>-->
<!--					Home-->
<!--				</button>-->
<!--			</mat-menu>-->
		</mat-toolbar>

    <mat-nav-list *ngIf="!(season$ | async)">
      <a
        mat-list-item
        *ngFor="let season of (seasons$ | async)?.slice().reverse(); let i = index"
        [routerLink]="['/seasons', season.model.year]"
        routerLinkActive="active"
      >
<!--        (click)="close()"-->
        <div mat-line>{{ season.model.name }}</div>
        <mat-divider [inset]="true"></mat-divider>
      </a>
    </mat-nav-list>

		<div *ngFor="let season of (season$ | async) ? [season$ | async] : []">
      <mat-nav-list *ngIf="!(race$ | async)">
        <!--			<a-->
        <!--				mat-list-item-->
        <!--				(click)="close()"-->
        <!--				[routerLink]="['/seasons', season.model.year]"-->
        <!--				routerLinkActive="active"-->
        <!--				[routerLinkActiveOptions]="{ exact: true }"-->
        <!--			>-->
        <!--				<div mat-line>Season {{ season.model.year }} Summary</div>-->
        <!--				<mat-divider [inset]="true"></mat-divider>-->
        <!--			</a>-->
        <a
          mat-list-item
          *ngFor="let race of races$ | async; let i = index"
          [routerLink]="['/seasons', season.model.year, 'races', race.model.id]"
          routerLinkActive="active"
        >
<!--          (click)="close()"-->
          <div mat-line>{{ race.model.name }}</div>
          <mat-divider [inset]="true"></mat-divider>
        </a>
      </mat-nav-list>

      <mat-nav-list *ngFor="let race of (race$ | async) ? [race$ | async] : []">
        <!--			<a-->
        <!--				mat-list-item-->
        <!--				(click)="close()"-->
        <!--				[routerLink]="['/seasons', season.model.year]"-->
        <!--				routerLinkActive="active"-->
        <!--				[routerLinkActiveOptions]="{ exact: true }"-->
        <!--			>-->
        <!--				<div mat-line>Season {{ season.model.year }} Summary</div>-->
        <!--				<mat-divider [inset]="true"></mat-divider>-->
        <!--			</a>-->
        <a
          mat-list-item
          *ngFor="let ranking of rankings$ | async; let i = index"
          (click)="close()"
          [routerLink]="['/seasons', season.model.year, 'races', race.model.id, 'rankings', ranking.model.id]"
          routerLinkActive="active"
        >
          <div mat-line>{{ ranking.model.name }}</div>
          <mat-divider [inset]="true"></mat-divider>
        </a>
      </mat-nav-list>
    </div>

	</mat-sidenav>
</mat-sidenav-container>
