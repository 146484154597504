import { Injectable } from '@angular/core';
import { mergeMap, map } from 'rxjs/operators';
import { Race } from '../../seasons/races/race';
import { Driver } from '../drivers/driver';
import { combineLatest, Observable } from 'rxjs';
import { Ranking } from '../../seasons/rankings/ranking';
import { RacesService } from '../../seasons/races/races.service';
import { DriversService } from '../drivers/drivers.service';
import { RankingsService } from '../../seasons/rankings/rankings.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsImport2Service {

  constructor(
    private racesService: RacesService,
    private driversService: DriversService,
    private rankingsService: RankingsService,
  ) {
  }

  loadRace(raceId: number): Observable<[Race, Ranking[], Driver[]]> {
    let currentRace: Race;

    return this.racesService.requestRaces(2021).pipe( // TODO Season selection
      map((races: Race[]) => currentRace = races.find(race => race.id === raceId)),
      (race$: Observable<Race>): Observable<[Race, Ranking[], Driver[]]> => combineLatest([
        race$,
        this.rankingsService.requestRankings(2021), // TODO Season selection
        race$.pipe(
          mergeMap(race => this.driversService.requestDrivers(race.driversCsvFile))
        )
      ])
    );
}
}
