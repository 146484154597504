import { Component, Input, OnInit } from '@angular/core';
import { Tag } from '../tag';

@Component({
  selector: 'mc-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent implements OnInit {

  @Input() tags: Tag[] = [];

  showCsvExport = false;

  constructor() {
  }

  ngOnInit() {
  }

}
