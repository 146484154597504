<table
	mat-table
	[dataSource]="standings"
	[trackBy]="trackStanding"
	class="race-ranking-standings-list"
	matSort
	(matSortChange)="recalculateOrder($event)"
	[matSortActive]="sortSettings.active"
	[matSortDirection]="sortSettings.direction"
	#raceRankingStandingsList
>
	<ng-container matColumnDef="position">
		<th mat-header-cell *matHeaderCellDef class="thead position" [ngClass]="{'sort-default': sortSettings.active === SORT_DEFAULT.ACTIVE && sortSettings.direction === SORT_DEFAULT.DIRECTION}" mat-sort-header="position" disableClear="true" title="Position">
			#
		</th>
		<td mat-cell *matCellDef="let standing" class="tbody position">
			{{standing.position}}.
		</td>
	</ng-container>
	<ng-container matColumnDef="start-number">
		<th mat-header-cell *matHeaderCellDef class="thead start-number" mat-sort-header="start-number" disableClear="true">
			Number
		</th>
		<td mat-cell *matCellDef="let standing" class="tbody start-number" title="RFID: {{standing.driver.epc}}">
			{{standing.driver.startNumber}}
		</td>
	</ng-container>
	<ng-container matColumnDef="driver">
		<th mat-header-cell *matHeaderCellDef class="thead driver" mat-sort-header="driver" disableClear="true">Driver</th>
		<td mat-cell *matCellDef="let standing" class="tbody driver">{{standing.driver.name}}</td>
	</ng-container>
	<ng-container matColumnDef="average">
		<th mat-header-cell *matHeaderCellDef class="thead average" mat-sort-header="average" disableClear="true">Average
		</th>
		<td mat-cell *matCellDef="let standing" class="tbody average" [class.complete]="standing.rankingResults?.length >= resultsConfig.rankingLapsNumber" title="Number of laps: {{standing.results?.length}}">
			Ø{{standing.rankingResultsAverage | laptimeFilter:'minutes'}}
		</td>
	</ng-container>
	<ng-container matColumnDef="season-points">
		<th mat-header-cell *matHeaderCellDef class="thead season-points">Points</th>
		<td mat-cell *matCellDef="let standing" class="tbody season-points">{{standing.seasonPoints}}</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let standing; columns: displayedColumns;"></tr>
</table>

<!--<table class="table" *ngIf="standings">-->
	<!--<thead>-->
		<!--<tr>-->
			<!--<th scope="col" class="position">#</th>-->
			<!--<th scope="col" class="start-number">Number</th>-->
			<!--<th scope="col">Driver</th>-->
			<!--<th scope="col">Average</th>-->
			<!--<th scope="col">Ranking results</th>-->
			<!--<th scope="col" class="season-points">Season pts</th>-->
		<!--</tr>-->
	<!--</thead>-->
	<!--<tbody>-->
		<!--<ng-container *ngFor="let standing of standings; trackBy: trackStanding">-->
			<!--<tr class="primary-row" (click)="detailsRow.hidden = !detailsRow.hidden">-->
				<!--<td scope="row" class="position">{{standing.position}}.</td>-->
				<!--<th class="start-number" title="RFID: {{standing.driver.epc}}">-->
					<!--{{standing.driver.startNumber}}-->
				<!--</th>-->
				<!--<td>{{standing.driver.name}}</td>-->
				<!--<td class="average" [class.complete]="standing.rankingResults?.length >= resultsConfig.rankingLapsNumber">-->
					<!--Ø{{standing.rankingResultsAverage | laptimeFilter:'minutes'}}-->
				<!--</td>-->
				<!--<td class="ranking-results">-->
          <!--<span *ngFor="let result of standing.rankingResults; trackBy: trackResult" class="result-wrapper">-->
            <!--<mc-result-->
							<!--[result]="result"-->
							<!--(changed)="recalculateOrder()"-->
							<!--(click)="$event.stopPropagation(); detailsRow.hidden = false"-->
						<!--&gt;</mc-result>-->
          <!--</span>-->
				<!--</td>-->
				<!--<td>-->
					<!--{{standing.seasonPoints}}-->
				<!--</td>-->
			<!--</tr>-->
			<!--<tr #detailsRow [hidden]="true" class="details-row" (click)="detailsRow.hidden = !detailsRow.hidden">-->
				<!--<td colspan="2"></td>-->
				<!--<td class="all-results" colspan="4">-->
					<!--<b>All results:</b>-->
					<!--<br />-->
					<!--<span-->
						<!--*ngFor="let result of standing.results; trackBy: trackResult"-->
						<!--class="result-wrapper"-->
						<!--[class.out-of-range]="!(result.microtime | laptimeRangeFilter:resultsConfig)"-->
					<!--&gt;-->
            <!--<mc-result-->
							<!--[result]="result"-->
							<!--(changed)="recalculateOrder()"-->
							<!--(click)="$event.stopPropagation()"-->
						<!--&gt;</mc-result>-->
          <!--</span>-->
				<!--</td>-->
			<!--</tr>-->
		<!--</ng-container>-->
	<!--</tbody>-->
<!--</table>-->
