import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap, mergeMap, shareReplay } from 'rxjs/operators';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Component({
  selector: 'mc-races',
  templateUrl: './season.component.html',
  styleUrls: ['./season.component.scss']
})
export class SeasonComponent {

  season$ = this.activeRoute.data.pipe(
    map(({season}) => season),
    shareReplay(1)
  );

  races$: Observable<AsyncCollectionElement[]> = this.season$.pipe(
    mergeMap((season): Observable<AsyncCollectionElement[]> => season.races$),
    shareReplay(1)
  );

  rankings$: Observable<AsyncCollectionElement[]> = this.season$.pipe(
    mergeMap((season): Observable<AsyncCollectionElement[]> => season.rankings$),
    shareReplay(1)
  );

  primaryRankings$ = this.rankings$.pipe(
    map(rankings => rankings.filter(ranking => ranking.model.standingsConfig.category)),
    shareReplay(1)
  );

  sideRankings$ = this.rankings$.pipe(
    map(rankings => rankings.filter(ranking => !ranking.model.standingsConfig.category)),
    shareReplay(1)
  );

  constructor(
    private activeRoute: ActivatedRoute
  ) {
  }
}
