import { Injectable } from '@angular/core';
import { Resolve,  RouterStateSnapshot,  ActivatedRouteSnapshot} from '@angular/router';
import { Observable, from } from 'rxjs';
import { switchMap, shareReplay, find, take } from 'rxjs/operators';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Injectable({
  providedIn: 'root'
})
export class RaceRankingsResolver implements Resolve<AsyncCollectionElement[]> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AsyncCollectionElement[]> {
    return from<Observable<AsyncCollectionElement>>(route.parent.data.seasons).pipe(
      find(season => season.model.year === +route.params.seasonYear),
      switchMap((season: AsyncCollectionElement): Observable<AsyncCollectionElement[]> => season.rankings$),
      take(1),
      shareReplay(1)
    );
  }
}
