import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Start page:
  // {path: '', component: DashboardComponent, data: {
  //   title: 'Miata Challenge'
  // }},
  // {path: '', redirectTo: '/seasons/:seasonYear/results', pathMatch: 'full'},
  // {path: '', redirectTo: '/seasons/:seasonYear/race-results/8', pathMatch: 'full'},
  // 404:
  // { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
