import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, take, shareReplay, find } from 'rxjs/operators';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Injectable({
  providedIn: 'root'
})
export class RaceRankingResolver implements Resolve<AsyncCollectionElement> {

  constructor(
    private router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AsyncCollectionElement> {
    return from<Observable<AsyncCollectionElement>>(route.parent.parent.data.rankings).pipe(
      find(ranking => ranking.model.id === +route.params.rankingId),
      map(ranking => {
        if (ranking) {
          return ranking;
        } else {
          this.router.navigate([
            '/seasons',
            route.parent.parent.params.seasonYear,
            'races',
            route.parent.params.raceId
          ]);
          return null;
        }
      }),
      take(1),
      shareReplay(1)
    );
  }
}
