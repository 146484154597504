import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataSourcesService } from './seasons/data-sources/data-sources.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit {

  constructor(
    private dataSourcesService: DataSourcesService
  ) {
    moment.locale('en');
    this.dataSourcesService.environmentId$.next(this.getEnvironmentId());
  }

  ngOnInit() {
  }

  getEnvironmentId() {
    return window.location.hostname;
  }
}
