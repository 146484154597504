import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, mergeMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';
import { SeasonPointsPipe } from '../season/season-points.pipe';

@Component({
  selector: 'mc-race',
  templateUrl: `./race.component.html`,
  styleUrls: ['./race.component.scss']
})
export class RaceComponent implements OnInit, OnDestroy {

  season$ = this.activeRoute.parent.parent.data.pipe(
    map(({season}) => season),
    shareReplay(1)
  );

  race$ = this.activeRoute.data.pipe(
    map(({race}) => race),
    shareReplay(1)
  );

  rankings$: Observable<AsyncCollectionElement[]> = this.season$.pipe(
    mergeMap((season): Observable<AsyncCollectionElement[]> => season.rankings$),
    shareReplay(1)
  );

  drivers$: Observable<AsyncCollectionElement[]> = this.race$.pipe(
    mergeMap((race: AsyncCollectionElement): Observable<AsyncCollectionElement[]> => race.drivers$),
    shareReplay(1)
  );

  primaryRankings$ = this.rankings$.pipe(
    map(rankings => rankings.filter(ranking => ranking.model.standingsConfig.category)),
    shareReplay(1)
  );

  sideRankings$ = this.rankings$.pipe(
    map(rankings => rankings.filter(ranking => !ranking.model.standingsConfig.category)),
    shareReplay(1)
  );

  SEASON_POINTS_MAP = SeasonPointsPipe.SEASON_POINTS_MAP;

  constructor(
    private activeRoute: ActivatedRoute
  ) {
    this.drivers$.subscribe()
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }
}
