import { Pipe, PipeTransform } from '@angular/core';
import { Breadcrumb } from './breadcrumb';

@Pipe({
  name: 'breadcrumbLabel'
})
export class BreadcrumbLabelPipe implements PipeTransform {

  transform(breadcrumb: Breadcrumb, ...args: unknown[]): string {
    if (breadcrumb && breadcrumb.data && breadcrumb.data.breadcrumb) {
      if (breadcrumb.data.breadcrumb instanceof Function) {
        return breadcrumb.data.breadcrumb.apply(null, [breadcrumb, ...args]);
      } else {
        return breadcrumb.data.breadcrumb;
      }
    } else {
      return null;
    }
  }

}
