import { Component } from '@angular/core';
import { ResultsSyncService } from './results-sync.service';

@Component({
  selector: 'mc-results-sync',
  templateUrl: './results-sync.component.html',
  styleUrls: ['./results-sync.component.scss']
})
export class ResultsSyncComponent {

  syncProgress$ = this.resultsSyncService.syncProgress$;

  constructor(
    private resultsSyncService: ResultsSyncService
  ) { }
}
