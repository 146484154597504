import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { Observable, zip, from, of } from 'rxjs';
import { take, tap, map, shareReplay, filter, mergeMap, toArray, startWith } from 'rxjs/operators';
import { Breadcrumb } from './breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  breadcrumbs$: Observable<Breadcrumb[]> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(this.router), // @see https://stackoverflow.com/questions/43237318/angular-2-router-event-not-firing-first-time
    map((event: NavigationEnd) => {
      let route = this.activeRoute;
      const cumulativeSegments = [];
      const cumulativeParams = [];
      const breadcrumbs = [];
      let depthIterator = 0;
      while (route.firstChild) {
        route = route.firstChild;
        // if (!route.parent.parent || (route.routeConfig.path && route.routeConfig.path.length > 0) || (route.routeConfig.data && route.routeConfig.data.breadcrumb)) {
        // }
        cumulativeSegments.push(route.url);
        cumulativeParams.push(route.params);
        breadcrumbs.push([
          zip(...cumulativeParams).pipe(
            map((params: {}[]) => params.reduce((a, b) => ({...a, ...b})))
          ),
          route.data,
          zip(...cumulativeSegments).pipe(
            map((url: UrlSegment[][]) => url.filter(segments => segments.length > 0).map(segments => segments.map(
              segment => segment.path
            ).join('/')).join('/'))
          ),
          route.url, // <- segments
          of(depthIterator++)
        ]);
      }
      return from(breadcrumbs).pipe(
        mergeMap((breadcrumbsStreams: Observable<Breadcrumb>[]) => zip(...breadcrumbsStreams)),
        take(breadcrumbs.length),
        map(([params, data, url, segments, depth]: [any, any, any, any, any]): Breadcrumb => ({params, data, url, segments, depth})),
        filter((breadcrumb: Breadcrumb) => (breadcrumb.depth < 1 || breadcrumb.segments.length > 0) && breadcrumb.data.breadcrumb),
        toArray(),
      );
    }),
    mergeMap(breadcrumbs => breadcrumbs),
    // tap(breadcrumbs => console.log('BREADCRUMBS update: ', breadcrumbs)),
    shareReplay(1)
  );

  state$: Observable<Breadcrumb> = this.breadcrumbs$.pipe(
    map((breadcrumbs: Breadcrumb[]): Breadcrumb => breadcrumbs[breadcrumbs.length - 1]),
    // tap(state => console.log('state$ changed to:', state)),
    shareReplay(1)
  );

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
  }
}
