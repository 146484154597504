<h1>Season standings</h1>

<div *ngIf="raceRankings && rankings">

	<ul class="nav nav-tabs mb-2" id="rankings-tabs-labels" role="tablist">
		<li class="nav-item" *ngFor="let ranking of rankings; let first = first">
			<a
				class="nav-link"
				[class.active]="first"
				data-toggle="tab"
				id="ranking-tab-{{ranking.ranking.id}}"
				href="#ranking-tab-content-{{ranking.ranking.id}}" role="tab"
			>{{ranking.ranking.name}}</a>
		</li>
	</ul>

	<div class="tab-content" id="rankings-tabs-contents">
		<div *ngFor="let ranking of rankings; let first = first"
			class="tab-pane"
			[class.active]="first"
			id="ranking-tab-content-{{ranking.ranking.id}}"
			role="tabpanel"
		>
			<table class="table">
				<thead>
					<tr>
						<th class="position">#</th>
						<th class="start-number">Number</th>
						<th>Driver</th>
						<th *ngFor="let raceRanking of raceRankings">
							<a [routerLink]="['ranking', 'races', raceRanking.race.id, 'results']">{{raceRanking.race.name}}, {{raceRanking.race.trackName}}</a>
							<br />
							Points (#Position)
						</th>
						<th>
							Season points
						</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let ds of ranking.driversStandings">
						<td class="position">{{ds.position}}.</td>
						<td class="start-number">{{ds.startNumber}}</td>
						<td>{{ds.name}}</td>
						<td *ngFor="let raceRanking of raceRankings">
							{{ds.racePoints[raceRanking.race.id] || 0}}{{ds.racePositions[raceRanking.race.id] ? ' (#' + ds.racePositions[raceRanking.race.id] + ')': '' }}
						</td>
						<td class="total-season-points">
							{{ds.seasonPoints}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>


<div *ngIf="loading">Loading...</div>
