import { Pipe, PipeTransform } from '@angular/core';
import { Standing } from '../standing';
import { StandingsConfig } from './standings-config';

@Pipe({
  name: 'standingsFilter'
})
export class StandingsFilterPipe implements PipeTransform {
  transform(standings: Standing[], standingsConfig: StandingsConfig): Standing[] {
    return standings.filter(standing =>
      (standingsConfig.category === undefined || standing.driver.category === standingsConfig.category)
      && (standingsConfig.spec === undefined || standing.driver.spec === standingsConfig.spec)
      && (standingsConfig.stock === undefined || standing.driver.stock === standingsConfig.stock)
      && (standingsConfig.women === undefined || standing.driver.women === standingsConfig.women)
      && (standingsConfig.lift === undefined || standing.driver.lift === standingsConfig.lift)
    );
  }
}
