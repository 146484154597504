import { Injectable } from '@angular/core';
import { TagsService } from '../tags/tags.service';
import * as moment from 'moment';
import { ReadingTime } from './reading-time';
import { GateReading } from '../gate-readings/gate-reading';

@Injectable({
  providedIn: 'root'
})
export class ReadingTimesService {

  readonly ERROR_MARGIN = 2000;

  readingTimes: ReadingTime[] = [];

  constructor(private tagsService: TagsService) {
  }

  setFromGateReading(gateReadings: GateReading[], minLapSeconds?: number, maxLapSeconds?: number) {
    this.readingTimes = this.fromGateReadings(gateReadings, minLapSeconds, maxLapSeconds);
  }

  findIgnoredTagCodes(gateReadings: GateReading[]) {
    const tagCodes = [];
    for (let i = 0; i < gateReadings.length; i++) {
      if (gateReadings[i] && !this.tagsService.findByEpc(gateReadings[i].tag_code)) {
        tagCodes.push(gateReadings[i].tag_code);
      }
    }
    return tagCodes;
  }

  fromGateReadings(gateReadings: GateReading[], minLapSeconds?: number, maxLapSeconds?: number): ReadingTime[] {
    const readingTimes: ReadingTime[] = [];
    const lastLapStartPerTag = {};
    const lapsCountPerTag = {};
    const minLapDuration = (minLapSeconds || 80) * 1000;
    const maxLapDuration = (maxLapSeconds || 300) * 1000;
    for (let i = 0; i < gateReadings.length; i++) {
      if (gateReadings[i]) {
        const tag = this.tagsService.findByEpc(gateReadings[i].tag_code);
        if (tag) {
          if (tag.EPC in lastLapStartPerTag) {
            const diff = new Date(gateReadings[i].first_detection_date + 'Z').getTime() - new Date(lastLapStartPerTag[tag.EPC].first_detection_date + 'Z').getTime();
            if (diff <= this.ERROR_MARGIN) {
              // Ignores gate reading.
            } else {
              lastLapStartPerTag[tag.EPC] = gateReadings[i];
              if (diff >= minLapDuration && diff <= maxLapDuration) {
                if (tag.EPC in lapsCountPerTag) {
                  lapsCountPerTag[tag.EPC]++;
                } else {
                  lapsCountPerTag[tag.EPC] = 0;
                }
                readingTimes.push({
                  Id: gateReadings[i].gr_id,
                  Zawodnik_Id: tag.Id,
                  Czas: moment.utc(diff).format('1900-01-01 HH:mm:ss.SSS'),
                  Lap: lapsCountPerTag[tag.EPC]
                } as ReadingTime);
              }
            }
          } else if (this.tagsService.findByEpc(tag.EPC)) {
            lastLapStartPerTag[tag.EPC] = gateReadings[i];
          }
        }
      }
    }
    return readingTimes;
  }
}
