import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Driver } from './driver';
import { FileReaderService } from '../../app-commons/file-reader/file-reader.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MocksService } from '../mocks/mocks.service';

@Injectable({
  providedIn: 'root'
})
export class DriversService {

  drivers: Driver[] = [];

  constructor(
    private fileReaderService: FileReaderService,
    private mocks: MocksService
  ) {
  }

  requestDrivers(mockPath: string): Observable<Driver[]> {
    return this.mocks.get(mockPath).pipe(
      map(csvContent => this.parse(csvContent))
    );
  }

  findByEpc(epc: string): Driver | undefined {
    return this.drivers.find(driver => driver.epc === epc);
  }

  findByStartNumber(startNumber: number): Driver | undefined {
    return this.drivers.find(driver => driver.startNumber === startNumber);
  }

  setFromCsv(csvContent) {
    this.drivers = this.parse(csvContent);
  }

  private parse(csvContent: string): Driver[] {
    return this.fileReaderService.parseCsv(csvContent, ',', null, null, true)
      .map(row => {
        return row;
      })
      .map(row => ({
        startNumber: +row[0],
        name: row[2],
        epc: row[3].replace(/\s+/, ''),
        category: +row[5],
        spec: +row[6] === 1 || +row[6] === 2 ? +row[6] : 0,
        stock: +row[6] === 3,
        women: row[7] === 'k',
        lift: row[8] === '1'
      } as Driver));
  }
}
