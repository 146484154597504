import { Injectable } from '@angular/core';
import { of, Observable, ReplaySubject } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { SeasonsService } from '../seasons.service';
import { cachedCollectionMap } from '../../app-commons/operators/cache.operators';
import { Season } from '../season/season';
import { RacesService } from '../races/races.service';
import { RankingsService } from '../rankings/rankings.service';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { Ranking } from '../rankings/ranking';
import { Race } from '../races/race';
import { Driver } from '../../gepard/drivers/driver';
import { LeaguesService } from '../leagues/leagues.service';
import { League } from '../leagues/league';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';
import { switchMap } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class DataSourcesService {

  environmentId$ = new ReplaySubject<string>(1);
  leagueIdToCache$ = new ReplaySubject<string>(1);
  // seasonId$ = new ReplaySubject<string>(1);
  // raceId$ = new ReplaySubject<string>(1);
  // rankingId$ = new ReplaySubject<string>(1);
  // driverId$ = new ReplaySubject<string>(1);

  leagues$: Observable<any> = this.environmentId$.pipe(
    cachedCollectionMap(
      instanceId => this.leaguesService.request(instanceId),
      (league: League): AsyncCollectionElement => ({
        model: league,
        seasons$: of(league.id).pipe(
          cachedCollectionMap(
            leagueId => this.seasonsService.requestSeasons(leagueId),
            (season: Season) => ({
              model: season,
              rankings$: of(season.year).pipe(
                cachedCollectionMap(
                  seasonId => this.rankingsService.requestRankings(seasonId),
                  (ranking: Ranking) => ({
                    model: ranking
                  })
                )
              ),
              races$: of([league.id, season.year]).pipe(
                cachedCollectionMap(
                  ([leagueId, seasonId]) => this.racesService.requestRaces(seasonId),
                  (race: Race) => ({
                    model: race,
                    drivers$: of(race.driversCsvFile).pipe(
                      cachedCollectionMap(
                        (driversCsvFile: string): Observable<Driver[]> => this.driversService.requestDrivers(driversCsvFile),
                        driver => ({
                          model: driver
                        })
                      )
                    )
                  })
                )
              )
            })
          )
        )
      })
    )
  );

  constructor(
    private leaguesService: LeaguesService,
    private seasonsService: SeasonsService,
    private rankingsService: RankingsService,
    private racesService: RacesService,
    private driversService: DriversService
  ) {
    this.leagues$.pipe(
      switchMap(leagues => leagues),
      take(1),
      tap((league: AsyncCollectionElement) => this.leagueIdToCache$.next(league.model.id))
    ).subscribe();
  }
}

