import { Component } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { BreadcrumbsService } from './breadcrumbs.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'mc-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  isHandset$ = this.layoutService.isHandset$.pipe(
    shareReplay(1)
  );

  constructor(
    private layoutService: LayoutService,
    public breadcrumbsService: BreadcrumbsService
  ) {
  }
}
