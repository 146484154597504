import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StandingsConfig } from '../standings-config';
import { ResultsConfig } from '../../results/results-config/results-config';

@Component({
  selector: 'mc-standings-moderation',
  templateUrl: './standings-moderation.component.html',
  styleUrls: ['./standings-moderation.component.scss']
})
export class StandingsModerationComponent implements OnInit {

  @Output() standingsConfigChanged = new EventEmitter<StandingsConfig>();
  @Output() resultsConfigChanged = new EventEmitter<ResultsConfig>();

  protected standingsConfig: StandingsConfig = {} as StandingsConfig;

  protected resultsConfig: ResultsConfig = {
    minimumLapSeconds: 60,
    maximumLapSeconds: 360,
    rankingLapsNumber: 3
  };

  set category(category: string|'') {
    this.standingsConfig.category = category === '' ? undefined : +category;
    this.emitStandingsConfig();
  }

  get category(): string {
    return this.standingsConfig.category === undefined ? '' : '' + this.standingsConfig.category;
  }

  set spec(spec: string|'') {
    this.standingsConfig.spec = spec === '' ? undefined : +spec;
    this.emitStandingsConfig();
  }

  get spec(): string {
    return this.standingsConfig.spec === undefined ? '' : '' + this.standingsConfig.spec;
  }

  set stock(stock: string|'') {
    this.standingsConfig.stock = stock === '' ? undefined : !!+stock;
    this.emitStandingsConfig();
  }

  get stock(): string {
    return this.standingsConfig.stock === undefined ? '' : +this.standingsConfig.stock + '';
  }

  set women(women: string|'') {
    this.standingsConfig.women = women === '' ? undefined : !!+women;
    this.emitStandingsConfig();
  }

  get women(): string {
    return this.standingsConfig.women === undefined ? '' : +this.standingsConfig.women + '';
  }

  set minimumLapSeconds(n: number) {
    this.resultsConfig.minimumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get minimumLapSeconds(): number {
    return this.resultsConfig.minimumLapSeconds;
  }

  set maximumLapSeconds(n: number) {
    this.resultsConfig.maximumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get maximumLapSeconds(): number {
    return this.resultsConfig.maximumLapSeconds;
  }

  set rankingLapsNumber(n: number) {
    this.resultsConfig.rankingLapsNumber = +n;
    this.emitResultsConfig();
  }

  get rankingLapsNumber(): number {
    return this.resultsConfig.rankingLapsNumber;
  }

  constructor() { }

  ngOnInit() {
    this.emitStandingsConfig();
    this.emitResultsConfig();
  }

  emitStandingsConfig() {
    this.standingsConfigChanged.emit({... this.standingsConfig});
  }

  emitResultsConfig() {
    this.resultsConfigChanged.emit({... this.resultsConfig});
  }

}
