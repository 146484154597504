import { Injectable } from '@angular/core';
import { Resolve,  RouterStateSnapshot,  ActivatedRouteSnapshot} from '@angular/router';
import { Observable, from } from 'rxjs';
import { switchMap, find, shareReplay, take } from 'rxjs/operators';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Injectable({
  providedIn: 'root'
})
export class DriversResolver implements Resolve<AsyncCollectionElement[]> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AsyncCollectionElement[]> {
    return from<Observable<AsyncCollectionElement>>(route.parent.data.races).pipe(
      find(race => race.model.id === +route.params.raceId),
      switchMap((race: AsyncCollectionElement): Observable<AsyncCollectionElement[]> => race.drivers$),
      take(1),
      shareReplay(1)
    );
  }
}
