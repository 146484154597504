import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'openUntil'
})
export class OpenUntilPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (isNaN(value)) {
      return 'Jeszcze nie otwarte';
    } else if (moment().isAfter(value)) {
      return 'Zakończone ' + moment(value).format('L');
    } else {
      return 'Otwarte do ' + moment(value).format('L');
    }
  }

}
