import { Directive } from '@angular/core';

@Directive({
  selector: '.mcSortable',
  exportAs: 'sortable'
})
export class SortableDirective {

  constructor() {
    console.log('test sortaable');
  }

}
