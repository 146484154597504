import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';

import { LayoutModule } from '../layout/layout.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SeasonResultsComponent } from './season-results/season-results.component';
import { SeasonRoutingModule } from './seasons-routing.module';
import { SeasonComponent } from './season/season.component';
import { RacesService } from './races/races.service';
import { RaceComponent } from './races/race.component';
import { RankingsService } from './rankings/rankings.service';
import { RaceRankingStandingsComponent } from './race-ranking-standings/race-ranking-standings.component';
import { RaceRankingStandingsListComponent } from './race-ranking-standings/race-ranking-standings-list/race-ranking-standings-list.component';
import { StandingsComponent } from './standings/standings.component';
import { StandingsModerationComponent } from './standings/standings-moderation/standings-moderation.component';
import { ResultComponent } from './results/result/result.component';
import { LaptimeRangeFilterPipe } from './results/result/laptime-range-filter.pipe';
import { LaptimeFilterPipe } from './results/result/laptime-filter.pipe';
import { ResultsFilterPipe } from './results/results-config/results-filter.pipe';
import { StandingsFilterPipe } from './standings/standings-filter.pipe';
import { SeasonPointsPipe } from './season/season-points.pipe';
import { RemoteResultsService } from './results/remote-results.service';
import { RaceRankingComponent } from './race-ranking/race-ranking.component';
import { ResultsSyncComponent } from './results/results-sync/results-sync.component';
import { SeasonsLayoutComponent } from './seasons-layout/seasons-layout.component';
import { LeaguesService } from './leagues/leagues.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,

    SeasonRoutingModule,
    LayoutModule
  ],
  declarations: [
    DashboardComponent,
    RaceComponent,
    SeasonResultsComponent,
    SeasonComponent,
    ResultComponent,
    StandingsComponent,
    StandingsModerationComponent,
    RaceRankingStandingsComponent,
    RaceRankingStandingsListComponent,
    LaptimeRangeFilterPipe,
    LaptimeFilterPipe,
    ResultsFilterPipe,
    StandingsFilterPipe,
    RaceRankingComponent,
    ResultsSyncComponent,
    SeasonPointsPipe,
    SeasonsLayoutComponent
  ],
  exports: [
    StandingsComponent,
    StandingsModerationComponent,
    RaceRankingStandingsComponent,
    ResultComponent
  ],
  providers: [
    LaptimeRangeFilterPipe,
    ResultsFilterPipe,
    StandingsFilterPipe,
    RankingsService,
    RemoteResultsService,
    RacesService,
    SeasonPointsPipe,
    LeaguesService
  ]
})
export class SeasonsModule {
}
