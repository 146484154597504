import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GateReadingsService } from '../../gepard/gate-readings/gate-readings.service';
import { forkJoin, merge, concat, throwError, combineLatest, Observable } from 'rxjs';
import { shareReplay, mergeMap, switchMap, mergeAll, scan, tap, map } from 'rxjs/operators';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { Race } from '../races/race';
import { StandingsService } from '../standings/standings.service';
import { StandingsFilterPipe } from '../standings/standings-filter.pipe';
import { ResultsService } from '../results/results.service';
import { Ranking } from '../rankings/ranking';
import { RemoteResultsService } from '../results/remote-results.service';
import { Result } from '../results/result/result';
import { Driver } from '../../gepard/drivers/driver';
import { ResultsSyncService } from '../results/results-sync/results-sync.service';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Component({
  selector: 'mc-race-results',
  templateUrl: './race-ranking.component.html',
  styleUrls: ['./race-ranking.component.scss']
})
export class RaceRankingComponent implements OnInit, OnDestroy {

  loading = true;

  race$: Observable<Race> = this.activeRoute.parent.parent.data.pipe(
    map(({race}): Race => race.model),
    tap(race => this.resultsService.clearState()),
    tap(race => this.remoteResultsService.clearState()),
    tap(race => this.remoteResultsService.syncOff()),
    shareReplay(1)
  );

  drivers$: Observable<Driver[]> = this.activeRoute.parent.parent.data.pipe(
    mergeMap(({race}): AsyncCollectionElement[] => race.drivers$),
    map(drivers => drivers.map(driver => driver.model)),
    tap((drivers: Driver[]) => this.driversService.drivers = drivers),
    shareReplay(1)
  );

  ranking$: Observable<Ranking> = this.activeRoute.data.pipe(
    map(({ranking}) => ranking.model),
    shareReplay(1)
  );

  rankingResults$: Observable<Ranking> = combineLatest([
    this.race$,
    this.drivers$,
    this.ranking$
  ]).pipe(
    tap(() => setTimeout(() => this.loading = true)),
    switchMap(([race, drivers, ranking]: [Race, Driver[], Ranking]) => merge(
      this.loadResults(race).pipe(
        tap(results => this.resultsService.mergeToState(results)),
        tap(results => this.remoteResultsService.broadcastChanges(results)),
      ),
      this.remoteResultsService.sync$.pipe(
        map(event => this.resultsService.results)
      )
    ).pipe(
      tap((results: Result[]) => ranking.standings = this.standingsFilter.transform(
        this.standingsService.fromResults(results),
        ranking.standingsConfig
      ).map(standing => this.standingsService.getRecalculated(standing, race.resultsConfig))),
      map((results): Ranking => ranking)
    )),
    tap(() => this.loading = false),
    shareReplay(1)
  );

  constructor(
    private activeRoute: ActivatedRoute,
    private gateReadingsService: GateReadingsService,
    private standingsService: StandingsService,
    private standingsFilter: StandingsFilterPipe,
    private resultsService: ResultsService,
    private remoteResultsService: RemoteResultsService,
    private resultsSyncService: ResultsSyncService,
    private driversService: DriversService,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.resultsSyncService.stop();
  }

  loadGateReadings(mockPaths: string[]) {
    return forkJoin(mockPaths.map(mockPath => this.gateReadingsService.requestGateReadings(mockPath))).pipe(
      mergeAll(),
      scan((a, b) => GateReadingsService.merge(a, b)),
    );
  }

  loadResults(race: Race): Observable<Result[]> {
    if ('resultsJsonFile' in race) {
      return this.resultsService.requestResults(race.resultsJsonFile);
    } else if ('resultsCouchDbName' in race) {
      return this.remoteResultsService.connect(race.resultsCouchDbName).pipe(
        switchMap(() => this.remoteResultsService.findAll()),
        tap(() => this.remoteResultsService.syncOn(true, false)),
      );
    }
    return throwError('No results source in race object.');
  }
}
