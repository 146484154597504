import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take, switchMap, find, shareReplay } from 'rxjs/operators';
import { DataSourcesService } from '../data-sources/data-sources.service';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Injectable({
  providedIn: 'root'
})
export class LeagueResolver implements Resolve<AsyncCollectionElement> {

  constructor(
    private dataSourcesService: DataSourcesService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AsyncCollectionElement> {
    return this.dataSourcesService.leagueIdToCache$.pipe(
      switchMap(leagueId => this.dataSourcesService.leagues$.pipe(
        switchMap(leagues => leagues),
        find((league: AsyncCollectionElement): boolean => league && league.model.id === leagueId),
      )),
      take(1),
      shareReplay(1)
    );
  }
}
