import { ApplicationRef , Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ResultsSyncEvent } from './results-sync-event';
import { RemoteResultsService } from '../remote-results.service';
import { ResultsSyncProgressEvent } from './results-sync-progress-event';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ResultsSyncService {

  syncProgress$: Observable<ResultsSyncProgressEvent> = this.remoteResultsService.sync$.pipe(
    map((event: ResultsSyncEvent) => {
      if (event.total > 0) {
        const newPercent = (event.total == 0 || event.total == event.processed) ? 100 : Math.floor(event.processed / event.total * 100);
        return {
          pending: event.pending,
          percent: newPercent,
          lastLapTime: moment(event.createdAt),
          lapsCount: event.processed
        };
      } else {
        return undefined;
      }
    }),
    // @see https://stackoverflow.com/questions/39511820/trigger-update-of-component-view-from-service-no-provider-for-changedetectorre
    tap(() => this.ref.tick())
  );

  constructor(
    private remoteResultsService: RemoteResultsService,
    private ref: ApplicationRef
  ) { }

  start(syncIn: boolean, syncOut: boolean) {
    return this.remoteResultsService.syncOn(syncIn, syncOut);
  }

  stop() {
    this.remoteResultsService.disconnect();
    this.remoteResultsService.syncOff();
  }
}
