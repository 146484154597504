import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MocksService } from '../mocks/mocks.service';
import { mergeMap, map, switchMap, tap, toArray } from 'rxjs/operators';
import { GateReadingsService } from '../gate-readings/gate-readings.service';
import { ResultsService } from '../../seasons/results/results.service';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { Driver } from '../drivers/driver';
import { GateReading } from '../gate-readings/gate-reading';
import { StandingsConfig } from '../../seasons/standings/standings-config';
import { ResultsConfig } from '../../seasons/results/results-config/results-config';
import { Result } from '../../seasons/results/result/result';
import { RankingsService } from '../../seasons/rankings/rankings.service';
import { Ranking } from '../../seasons/rankings/ranking';
import { RacesService } from '../../seasons/races/races.service';
import { Race } from '../../seasons/races/race';
import { StandingsService } from '../../seasons/standings/standings.service';
import { StandingsFilterPipe } from '../../seasons/standings/standings-filter.pipe';
import { DriversService } from '../drivers/drivers.service';
import { ResultsImport2Service } from './results-import2.service';
import { RemoteResultsService } from '../../seasons/results/remote-results.service';
import { Standing } from '../../seasons/standing';

@Component({
  selector: 'mc-results-import2',
  templateUrl: './results-import2.component.html',
  styleUrls: ['./results-import2.component.scss']
})
export class ResultsImport2Component implements OnInit, OnDestroy, AfterViewInit {

  readonly RFID_ACCURACY_THRESHOLD = 2;

  race: Race;
  rankings: Ranking[] = []; // Rankings of the race.
  standings: Standing[] = [];

  gateReadings: GateReading[] = [];

  drivers$ = new BehaviorSubject<Driver[]>([]);
  standingsConfig$ = new BehaviorSubject<StandingsConfig>({});
  resultsConfig$ = new BehaviorSubject<ResultsConfig>({
    rankingLapsNumber: 3,
    minimumLapSeconds: 60,
    maximumLapSeconds: 360
  });

  gateReadings$ = new BehaviorSubject<GateReading[]>([]);

  resolveSubscription: Subscription;
  resultSubscription: Subscription;
  gateReadingsSubscription: Subscription;
  dbConnectionSubscription: Subscription;
  syncSubscription: Subscription;

  jsonExport = null;
  showLocalResultsPreview = false;

  resultsFromGateReadings$: Observable<Result[]> = combineLatest([
    this.drivers$, // TODO Remove it and use takeWhite instead...
    this.standingsConfig$,
    this.resultsConfig$
  ]).pipe(
    switchMap(([drivers, standingsConfig, resultsConfig]: [Driver[], StandingsConfig, ResultsConfig]) => this.gateReadings$.pipe(
      tap((gateReadings: GateReading[]) => this.gateReadings = gateReadings),
      mergeMap((gateReadings: GateReading[]) => from(gateReadings).pipe(
        map((gateReading: GateReading) => [gateReading, this.RFID_ACCURACY_THRESHOLD]),
        this.gateReadingsService.filterThresholdOperator,
        // withLatestFrom(this.resultsConfig$),
        // map(([gateReading, resultsConfig]: [GateReading, ResultsConfig]) => [gateReading, resultsConfig.minimumLapSeconds, resultsConfig.maximumLapSeconds]),
        // this.gateReadingsService.filterLaptimeDurationOperator,
        map(gateReading => [gateReading, drivers, standingsConfig, resultsConfig]),
        (stream: Observable<[GateReading, Driver[], StandingsConfig, ResultsConfig]>) => this.gateReadingsService.mapToResultsOperator(stream),
        toArray()
      ))
    ))
  );

  constructor(
    private mocks: MocksService,
    private gateReadingsService: GateReadingsService,
    public resultsService: ResultsService,
    private rankingsService: RankingsService,
    private racesService: RacesService,
    private standingsService: StandingsService,
    private standingsFilter: StandingsFilterPipe,
    private driversService: DriversService,
    private resultsImport2Service: ResultsImport2Service,
    public remoteResultsService: RemoteResultsService
  ) {
  }

  ngOnInit() {
    this.resolveSubscription = this.resultsImport2Service.loadRace(8).pipe(
      tap(([race, rankings, drivers]: [Race, Ranking[], Driver[]]) => {
        this.race = race;
        this.rankings = rankings;
        this.updateDrivers(drivers);
      }),
    ).subscribe(([race, rankings, drivers]: [Race, Ranking[], Driver[]]) => {

      this.gateReadingsSubscription = this.resultsFromGateReadings$.subscribe(results => {
        const changed = this.resultsService.mergeToState(results);
        console.log('state: ' + this.resultsService.results.length + ', changed: ' + changed.length);
        this.recalculateRankings(this.standingsService.fromResults(this.resultsService.results));
        this.remoteResultsService.broadcastChanges(changed);
        if (this.remoteResultsService.isSyncing()) {
          this.remoteResultsService.db.bulkDocs(changed);
        }
      });
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.resolveSubscription) {
      this.resolveSubscription.unsubscribe();
    }
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
    if (this.gateReadingsSubscription) {
      this.gateReadingsSubscription.unsubscribe();
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
    this.resultsService.replaceState([]);
  }

  trackRanking(index: number, ranking: Ranking) {
    return ranking.id;
  }

  test(gateReadings, intervalSeconds) {
    const n = gateReadings.length;
    let i = 0;
    console.log('Test started...');
    const loop = setInterval(() => {
      if (i > n) {
        clearInterval(loop);
        console.log('Test finished.');
      } else {
        console.log('Test iteration: ' + i);
        this.gateReadings$.next(gateReadings.slice(0, i));
      }
      i++;
    }, intervalSeconds * 1000);
  }

  recalculateRankings(standings: Standing[]) {
    this.standings = standings.map(standing => this.standingsService.getRecalculated(standing, this.race.resultsConfig));
  }

  updateDrivers(drivers: Driver[]) {
    this.driversService.drivers = drivers;
    this.drivers$.next(drivers);
  }

  updateStandingsConfig(standingsConfig: StandingsConfig) {
    this.standingsConfig$.next(standingsConfig);
  }

  updateResultsConfig(resultsConfig: ResultsConfig) {
    this.resultsConfig$.next(resultsConfig);
  }

  updateGateReadings(gateReadings: GateReading[]) {
    this.gateReadings$.next(gateReadings);
  }

  connect(dbName: string) {
    this.dbConnectionSubscription = this.remoteResultsService.connect(dbName).pipe(
      mergeMap(() => this.refreshResults())
    ).subscribe(newResults => {
      console.log('Connected to ' + dbName + '.');
    });
  }

  disconnect() {
    this.dbConnectionSubscription.unsubscribe();
    return this.remoteResultsService.disconnect();
  }

  isConnected() {
    return this.remoteResultsService.isConnected();
  }

  refreshResults() {
    console.log('Refreshing results...');
    return this.remoteResultsService.findAll().pipe(
      tap(results => console.log('Refreshed results', results.length)),
      map(results => this.resultsService.mergeToState(results)),
      tap(changed => console.log('Refreshed changes', changed.length)),
      tap(changed => this.remoteResultsService.broadcastChanges(changed))
    );
  }

  sync(toggle) {
    if (toggle) {
      console.log('Turning sync on...');
    } else {
      console.log('Turning sync off...');
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
    if (toggle) {
      this.syncSubscription = this.remoteResultsService.syncOn(true, true).pipe(
        // takeWhile(state => !state),
        mergeMap(state => toggle ? this.refreshResults() : [[]])
      ).subscribe(newResults => {
        if (toggle) {
          console.log('Sync is now on.');
          console.log('Pulled ' + newResults.length + ' results after sync.');
        } else {
          console.log('Sync is now off.');
        }
      });
    } else {
      this.remoteResultsService.syncOff();
      console.log('Sync is now off.');
    }
  }

  exportGateReadings() {
    this.jsonExport = this.gateReadings;
  }

  exportResults() {
    this.jsonExport = this.resultsService.results;
  }

  clearChangedResultsState() {
    this.resultsService.clearState();
    this.remoteResultsService.clearState();
    this.updateGateReadings([]);
  }
}
