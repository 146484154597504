import { Component, OnInit } from '@angular/core';
import { MocksService } from '../mocks/mocks.service';
import { RacesService } from '../../seasons/races/races.service';
import { Race } from '../../seasons/races/race';
import { ResultsService } from '../../seasons/results/results.service';
import {
  filter,
  mergeMap,
  map,
  switchMap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';
import { Observable, BehaviorSubject, from, combineLatest } from 'rxjs';
import { Result } from '../../seasons/results/result/result';
import { DriversService } from '../drivers/drivers.service';
import { Driver } from '../drivers/driver';
import * as moment from 'moment';
import { GateReadingsService } from '../gate-readings/gate-readings.service';
import { ResultsConfig } from '../../seasons/results/results-config/results-config';
import { StandingsConfig } from '../../seasons/standings/standings-config';
import { GateReading } from '../gate-readings/gate-reading';
import { PzGateReading } from './pz-gate-reading';
import { StandingsService } from '../../seasons/standings/standings.service';
import { Standing } from '../../seasons/standing';

@Component({
  selector: 'mc-backup-results-merge',
  templateUrl: './backup-results-merge.component.html',
  styleUrls: ['./backup-results-merge.component.scss']
})
export class BackupResultsMergeComponent implements OnInit {

  race$: Observable<Race> = this.racesService.requestRaces(2021).pipe( // TODO Season selection
    mergeMap(races => races),
    filter(race => race.id === 2)
  );

  standingsConfig$ = new BehaviorSubject<StandingsConfig>({});
  resultsConfig$ = new BehaviorSubject<ResultsConfig>({});

  standings$: Observable<Standing[]> = this.race$.pipe(
    switchMap((race: Race) => {
      this.resultsConfig$.next(race.resultsConfig);

      return combineLatest([
        from([race]),
        this.driversService.requestDrivers(race.driversCsvFile)
      ]);
    }),
    switchMap(([race, drivers]: [Race, Driver[]]) => {
      this.driversService.drivers = drivers;

      return combineLatest([
        this.mocksService.get('20190511/partial-backup-results.json').pipe(
          switchMap(jsonContent => JSON.parse(jsonContent)),
          (stream: Observable<PzGateReading>) => this.pzGateReadingToGateReadingOperator(stream),
          toArray()
        ),
        from(race.gateReadingsCsvFiles).pipe(
          mergeMap(gateReadingsCsvFile => this.gateReadingsService.requestGateReadings(gateReadingsCsvFile)),
        )
      ]).pipe(
        mergeMap((gateReadingsCollections: GateReading[][]): Observable<GateReading> => GateReadingsService.concat.apply(null, gateReadingsCollections)),
        map((gateReading: GateReading): [GateReading, number] => [gateReading, 2]),
        (stream: Observable<[GateReading, number]>): Observable<GateReading> => this.gateReadingsService.filterThresholdOperator(stream),
        withLatestFrom([drivers], this.standingsConfig$, this.resultsConfig$),
        // tap(([gateReading, drivers, standingsConfig, resultsConfig]: [GateReading, Driver[], StandingsConfig, ResultsConfig]) => console.log(gateReading, drivers, standingsConfig, resultsConfig)),
        (stream: Observable<[GateReading, Driver[], StandingsConfig, ResultsConfig]>): Observable<Result> => this.gateReadingsService.mapToResultsOperator(stream),
        // filter((result: Result) => result.driverId === 91 && result.createdAt > '2019-05-11 11:47:00' && result.createdAt < '2019-05-11 11:49:00'),
        toArray(),
        // tap(r => console.log(JSON.stringify(r))),
        mergeMap((results: Result[]): Observable<Standing[]> => from(this.standingsService.fromResults(results)).pipe(
          withLatestFrom(this.resultsConfig$),
          map(([standing, resultsConfig]: [Standing, ResultsConfig]): Standing => this.standingsService.getRecalculated(standing, resultsConfig)),
          toArray()
        ))
      );
    })
  );

  constructor(
    private racesService: RacesService,
    private resultsService: ResultsService,
    private mocksService: MocksService,
    private driversService: DriversService,
    private gateReadingsService: GateReadingsService,
    private standingsService: StandingsService
  ) {
  }

  ngOnInit() {
  }

  pzGateReadingToGateReadingOperator(stream: Observable<PzGateReading>): Observable<GateReading> {
    return stream.pipe(
      map((pzGateReading: PzGateReading) => {
        const createdAt = moment.utc(pzGateReading.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS');
        return {
          gr_id: pzGateReading.internal_id + 10000000,
          gate_id: 20,
          tag_code: pzGateReading.tag_id.substr(6, 8),
          first_detection_date: createdAt,
          last_detection_date: createdAt,
          save_date: createdAt,
          status: 0,
          lap: null
        };
      })
    );
  }

}
