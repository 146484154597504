import { Injectable } from '@angular/core';
import { merge, of, BehaviorSubject, Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { startWith, find, mergeMap, filter, tap, map, shareReplay, distinctUntilChanged, distinctUntilKeyChanged, switchMap } from 'rxjs/operators';
import { cache, cachedSwitchMap } from '../../app-commons/operators/cache.operators';
import { MocksService } from '../../gepard/mocks/mocks.service';
import { Race } from './race';

@Injectable({
  providedIn: 'root'
})
export class RacesService {

  races: Race[] = []; // TODO Remove in favour of races$

  race$ = new ReplaySubject<Race>(1);

  racesResource$ = new ReplaySubject();

  // ---

  seasonId$ = new ReplaySubject<number>(1);

  racesCache$ = this.seasonId$.pipe(
    cache(seasonId => this.requestRaces(seasonId).pipe(
      tap(races => console.log(8, 'From AJAX: ', seasonId, races)),
      map(races => new BehaviorSubject(races))
    )),
    tap(seasonId => console.log(9, 'Cache state: ', seasonId)),
  );

  racesSource$ = this.seasonId$.pipe(
    switchMap(seasonId => this.racesCache$.pipe(
      // find(([key, races]) => key === seasonId)
      tap(([key, races$]) => console.log(10, 'From cache', key, races$)),
      find(([key, races$]) => key === seasonId),
    )),
    tap(a => console.log(11, 'After from cache: ', a)),
    map(([key, races$]) => races$),
    tap(a => console.log(12, 'After from cache2: ', a)),
    // map(a => of(a)),
    shareReplay(1),
  ).pipe(
    tap(races$ => console.log('=== RACES FROM CACHE', races$)),
    shareReplay(1)
  );

  races$$: Observable<Observable<Race[]>> = this.racesSource$.pipe(
    tap(a => console.log('==== cache', a)),
    // map(([key, races$]) => races$),
    // tap(a => console.log('==== unpacked', a)),
    shareReplay(1)
  );

  races$: Observable<any> = this.racesSource$.pipe(
    mergeMap(races$ => races$),
    shareReplay(1)
  );

  constructor(
    private mocks: MocksService
  ) {
  }

  requestRaces(seasonYear): Observable<Race[]> {
    return this.mocks.get('races/mc' + seasonYear + '.json').pipe(
      map(json => JSON.parse(json)),
      tap(races => console.log('Downloading ' + seasonYear + ' races list:', races.length))
    );
  }
}
