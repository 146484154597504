import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  sortBy: {
    column: string,
    direction: string
  } = {
    column: '',
    direction: 'asc'
  };

  @Input() collection: {}[];

  constructor() {}

  ngOnInit() {
  }

  public setSortBy(column: string, direction: string = null) {
    if (column === this.sortBy.column) {
      this.sortBy.direction = direction ? direction : (this.sortBy.direction === 'desc' ? 'asc' : 'desc');
    } else {
      this.sortBy.column = column;
      this.sortBy.direction = direction ? direction : 'asc';
    }
    this.sort(this.sortBy.column, this.sortBy.direction);
  }

  private sort(column, direction) {
    this.collection.sort((s1, s2) => {
      if (isNaN(s1[column]) || isNaN(s1[column])) {
        return s1[column].toString().localeCompare(s2[column].toString()) * (direction === 'desc' ? -1 : 1);
      } else {
        return (s1[column] === s2[column] ? 0 : (s1[column] > s2[column] ? 1 : -1)) * (direction === 'desc' ? -1 : 1);
      }
      //   return direction === 'desc' ? -1 : 1;
      // }
      // if (s1[column] < s2[column]) {
      //   return direction === 'desc' ? 1 : -1;
      // }
      // return 0;
    });
  }

  update({name}: {name: string}) {
  }

  delete(id: number) {

  }

  readAll() {

  }
}
