<mc-layout-content>

  <h2>Races</h2>

  <div class="section fullscreen">
    <a
      *ngFor="let race of races$ | async"
      [routerLink]="['races', race.model.id]"
      routerLinkActive="active"
      class="tile-link side-paddings"
    >
      <span>
        <b>{{ race.model.name }}</b>:
        {{ race.model.trackName }}
      </span>
      <span>
        <small>{{ race.model.date | date:'MMMM d (EEEE)' }}</small>
      </span>
    </a>
  </div>

  <h2>Primary rankings</h2>

  <div class="section fullscreen">
    <a
      *ngFor="let ranking of primaryRankings$ | async"
      class="tile-link side-paddings"
      [routerLink]="['results']"
      routerLinkActive="active"
    >
      <span>{{ ranking.model.name }}</span>
      <span>{{ ranking.model.description }}</span>
    </a>
  </div>

  <h2>Side rankings</h2>

  <div class="section fullscreen">
    <a
      *ngFor="let ranking of sideRankings$ | async"
      class="tile-link side-paddings"
      [routerLink]="['results']"
      routerLinkActive="active"
    >
      <span>{{ ranking.model.name }}</span>
      <span>{{ ranking.model.description }}</span>
    </a>
  </div>
</mc-layout-content>
