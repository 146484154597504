import { Injectable } from '@angular/core';
import { Subject, from, of, Observable, AsyncSubject, ReplaySubject, BehaviorSubject, merge, zip, combineLatest } from 'rxjs';
import { mergeMapTo, toArray, find, switchMap, mergeMap, filter, distinct, tap, map, shareReplay } from 'rxjs/operators';
import { MocksService } from '../gepard/mocks/mocks.service';
import { Season } from './season/season';
import { cachedSwitchMap, cache } from '../app-commons/operators/cache.operators';
import { RacesService } from './races/races.service';

@Injectable({
  providedIn: 'root'
})
export class SeasonsService {

  leagueId$ = new ReplaySubject<string>(1);

  seasonsResource$ = new ReplaySubject<Season[]>(1);

  seasonsSource$ = this.leagueId$.pipe(
    cachedSwitchMap(leagueId => leagueId ? this.requestSeasons(leagueId) : of(null)),
    shareReplay(1)
  );

  league$: Observable<{leagueId: string, seasons: any}> = this.leagueId$.pipe( // TODO Correct types
    switchMap(leagueId => merge(this.seasonsSource$, this.seasonsResource$).pipe(
    // switchMap(leagueId => this.seasonsSource$.pipe(
      map(seasons => ({
        leagueId,
        seasons
      })),
    )),
    shareReplay(1)
  );

  seasons$ = this.league$.pipe(
    tap(({leagueId}) => console.log('League changed to: ', leagueId)),
    map(({seasons}) => seasons),
    shareReplay(1)
  );

  season$ = new Subject<Season>();

  seasons2$ = this.seasons$.pipe(
    map((seasons: Season[]) => seasons.map(season => this.racesService.racesCache$.pipe(
      find(([key, races$]) => key === season.year),
      map(([key, races$]) => ({
        season,
        races$
      }))
    )))

    // map(season => ({
    //   season,
    //   races$: this.racesService
    // }))
  ).pipe(
    tap(a => console.log(1000, 'season2$ 2', a))
  );

  constructor(
    private mocks: MocksService,
    private racesService: RacesService
  ) {
  }

  requestSeasons(leagueId: string): Observable<Season[]> {
    return this.mocks.get('seasons/' + leagueId + '.json').pipe(
      // tap(seasons => console.log('Downloading "' + leagueId + '" league seasons list:', seasons)),
      map(json => JSON.parse(json))
    );
  }
}
