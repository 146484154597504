import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TagsService } from './tags.service';
import { Tag } from './tag';

@Component({
  selector: 'mc-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  @Output() changed = new EventEmitter<Tag[]>();

  constructor(private tagsService: TagsService) { }

  ngOnInit() {
    this.update('225;1C01 0033;5;1\n' +
      '226;1C01 0034;5;2\n' +
      '227;1C01 0035;5;3\n' +
      '228;1C01 0036;5;4\n' +
      '229;1C01 0037;5;5\n' +
      '230;1C01 0038;5;6\n' +
      '231;1C01 0039;5;7\n' +
      '232;1C01 0040;5;8\n' +
      '233;1C01 0041;5;9\n' +
      '234;1C01 0042;5;10\n' +
      '235;1C01 0043;5;11\n' +
      '236;1C01 0044;5;12\n' +
      '237;1C01 0045;5;13\n' +
      '238;1C01 0046;5;14\n' +
      '239;1C01 0047;5;15\n' +
      '240;1C01 0048;5;16\n' +
      '241;1C01 0049;5;17\n' +
      '242;1C01 0050;5;18\n' +
      '243;1C01 0051;5;19\n' +
      '244;1C01 0052;5;20\n' +
      '245;1C01 0053;5;21\n' +
      '246;1C01 0054;5;22\n' +
      '247;1C01 0055;5;23\n' +
      '248;1C01 0056;5;24\n' +
      '249;1C01 0057;5;25\n' +
      '250;1C01 0058;5;26\n' +
      '251;1C01 0059;5;27\n' +
      '252;1C01 0060;5;28\n' +
      '253;1C01 0061;5;29\n' +
      '254;1C01 0062;5;30\n' +
      '255;1C01 0063;5;31\n' +
      '256;1C01 0064;5;32\n' +
      '257;1C01 0065;5;33\n' +
      '258;1C01 0066;5;34\n' +
      '259;1C01 0067;5;35\n' +
      '260;1C01 0068;5;36\n' +
      '261;1C01 0069;5;37\n' +
      '262;1C01 0070;5;38\n' +
      '263;1C01 0071;5;39\n' +
      '264;1C01 0072;5;40\n' +
      '265;1C01 0073;5;41\n' +
      '266;1C01 0074;5;42\n' +
      '267;1C01 0075;5;43\n' +
      '268;1C01 0076;5;44\n' +
      '269;1C01 0077;5;45\n' +
      '270;1C01 0078;5;46\n' +
      '271;1C01 0079;5;48\n' +
      '272;1C01 0080;5;49\n' +
      '273;1C01 0081;5;50\n' +
      '274;1C01 0082;5;51\n' +
      '275;1C01 0083;5;52\n' +
      '276;1C01 0084;5;53\n' +
      '277;1C01 0085;5;54\n' +
      '278;1C01 0086;5;55\n' +
      '279;1C01 0087;5;57\n' +
      '280;1C01 0088;5;60\n' +
      '281;1C01 0089;5;61\n' +
      '282;1C01 0090;5;64\n' +
      '283;1C01 0091;5;69\n' +
      '284;1C01 0092;5;72\n' +
      '285;1C01 0093;5;74\n' +
      '286;1C01 0094;5;75\n' +
      '287;1C01 0095;5;76\n' +
      '288;1C01 0096;5;77\n' +
      '289;1C01 0097;5;78\n' +
      '290;1C01 0098;5;81\n' +
      '291;1C01 0099;5;82\n' +
      '292;1C01 0100;5;83\n' +
      '293;1C01 0101;5;86\n' +
      '294;1C01 0102;5;87\n' +
      '295;1C01 0103;5;89\n' +
      '296;1C01 0104;5;93\n' +
      '297;1C01 0105;5;94\n' +
      '298;1C01 0106;5;99\n' +
      '299;1C01 0107;5;100\n' +
      '300;1C01 0108;5;108\n' +
      '301;1C01 0109;5;113\n' +
      '302;1C01 0110;5;313\n' +
      '303;1C01 0111;5;666\n' +
      '304;1C01 0112;5;47\n' +
      '305;1C01 0113;5;56\n' +
      '306;1C01 0114;5;58\n' +
      '307;1C01 0115;5;59\n' +
      '308;1C01 0116;5;70\n' +
      '309;1C01 0117;5;720\n' +
      '310;1C01 0118;5;63\n' +
      '311;1C01 0121;5;65\n' +
      '312;1C01 0122;5;66\n' +
      '313;1C01 0123;5;67\n' +
      '314;1C01 0124;5;68\n' +
      '315;1C01 0125;5;62\n' +
      '316;1C01 0126;5;71\n' +
      '317;1C01 0127;5;73\n' +
      '318;1C01 0130;5;79\n' +
      '319;1C01 0131;5;80\n' +
      '320;1C01 0133;5;84\n' +
      '321;1C01 0134;5;85\n' +
      '322;1C01 0135;5;91\n' +
      '323;1C01 0136;5;88\n' +
      '324;1C01 0137;5;90\n' +
      '325;1C01 0138;5;92\n');
  }

  get tags(): Tag[] {
    return this.tagsService.tags;
  }

  update(csvContent: string) {
    this.tagsService.setFromCsv(csvContent);
    this.changed.emit(this.tagsService.tags);
  }

}
