import { Component, OnInit } from '@angular/core';
import { GateReading } from '../gate-readings/gate-reading';
import { Driver } from '../drivers/driver';
import { DriversService } from '../drivers/drivers.service';
import { combineLatest, forkJoin } from 'rxjs';
import { TagsService } from '../tags/tags.service';
import { mergeAll, scan } from 'rxjs/operators';
import { Tag } from '../tags/tag';
import { GateReadingsService } from '../gate-readings/gate-readings.service';
import { StandingsConfig } from '../../seasons/standings/standings-config';
import { ResultsConfig } from '../../seasons/results/results-config/results-config';

@Component({
  selector: 'mc-results-import',
  templateUrl: './results-import.component.html',
  styleUrls: ['./results-import.component.scss']
})
export class ResultsImportComponent implements OnInit {

  drivers: Driver[] = [];
  tags: Tag[] = [];
  gateReadings: GateReading[] = [];

  standingsConfig: StandingsConfig = {};
  resultsConfig: ResultsConfig = {};

  constructor(
    private driversService: DriversService,
    private tagsService: TagsService,
    private gateReadingsService: GateReadingsService
  ) {
  }

  ngOnInit() {
    // forkJoin([
    //   this.driversService.requestDrivers(),
    //   this.tagsService.requestTags(),
    //   this.loadGateReadings()
    // ]).subscribe(([drivers, tags, gateReadings]) => {
    //   this.drivers = this.driversService.drivers = drivers;
    //   this.tags = tags;
    //   this.gateReadings = gateReadings;
    // })
  }

  loadGateReadings() {
    return forkJoin([
      this.gateReadingsService.requestGateReadings('20180520/gate-readings.csv'),
      this.gateReadingsService.requestGateReadings('20180520/gate-readings-backup.csv')
    ]).pipe(
      mergeAll(),
      scan((a, b) => GateReadingsService.merge(a, b)),
    );
  }

  appendGateReadings(gateReadings: GateReading[]) {
    this.gateReadings = GateReadingsService.merge(this.gateReadings, gateReadings);
  }

  clearGateReadings() {
    this.gateReadings = [];
  }
}
