import { Component, Input, OnInit } from '@angular/core';
import { ReadingTimesService } from './reading-times.service';
import { ReadingTime } from './reading-time';
import { GateReading } from '../gate-readings/gate-reading';

@Component({
  selector: 'mc-reading-times',
  templateUrl: './reading-times.component.html',
  styleUrls: ['./reading-times.component.scss']
})
export class ReadingTimesComponent implements OnInit {

  readingTimes: ReadingTime[] = [];
  ignoredTagCodes: string[] = [];
  showCsvExport = false;
  showIgnoredTags = false;

  @Input() set gateReadings(gateReadings: GateReading[]) {
    this.ignoredTagCodes = this.readingTimesService.findIgnoredTagCodes(gateReadings);
    this.readingTimes = this.readingTimesService.fromGateReadings(gateReadings);
  }

  constructor(private readingTimesService: ReadingTimesService) {
  }

  ngOnInit() {
  }
}
