<form novalidate>
	<div>
		<label>
			<input type="checkbox" (change)="showCsvExport = $event.target.checked" />
			{{readingTimes.length}} reading times.
		</label>

		<div *ngIf="showCsvExport">
			<hr />
			<div *ngFor="let readingTime of readingTimes">
				{{readingTime.Id}};{{readingTime.Zawodnik_Id}};{{readingTime.Czas}};{{readingTime.Lap}}
			</div>
		</div>
	</div>

	<div>
		<label>
			<input type="checkbox" (change)="showIgnoredTags = $event.target.checked" />
			{{ignoredTagCodes.length}} rejected reading times (tag ignored).
		</label>
		<div *ngIf="showIgnoredTags">
			<hr />
			<div *ngFor="let tagCode of ignoredTagCodes">
				{{tagCode}}
			</div>
		</div>
	</div>
</form>
