import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeasonResultsComponent } from './season-results/season-results.component';
import { RaceComponent } from './races/race.component';
import { SeasonComponent } from './season/season.component';
import { SeasonsLayoutComponent } from './seasons-layout/seasons-layout.component';
import { RoutingSlotComponent } from '../layout/routing-slot/routing-slot.component';
import { RaceResolver } from './races/race.resolver';
import { RacesResolver } from './races/races.resolver';
import { SeasonsResolver } from './seasons.resolver';
import { SeasonResolver } from './season/season.resolver';
import { LeagueResolver } from './leagues/league.resolver';
import { RaceRankingsResolver } from './race-ranking/race-rankings.resolver';
import { DriversResolver } from './drivers/drivers.resolver';
import { RaceRankingResolver } from './race-ranking/race-ranking.resolver';
import { RaceRankingComponent } from './race-ranking/race-ranking.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const rankingRoutes: Routes = [
  {
    /**
     * Resolvers fetch the seasons and races lists and push them into the seasons$, season$ and races$ BehaciourSubjects in the SeasonsService.
     * SeasonsLayoutComponent uses SeasonsService to fetch seasons and races information for rendering the menu.
     */
    path: '',
    component: SeasonsLayoutComponent,
    data: {
      breadcrumb: ({data}) => data.league.model.name
    },
    resolve: {
      league: LeagueResolver,
      seasons: SeasonsResolver
    },
    children: [{
      path: '',
      component: DashboardComponent
    }, {
      path: 'seasons/:seasonYear',
      component: RoutingSlotComponent,
      data: {
        breadcrumb: ({params}) => params.seasonYear
      },
      resolve: {
        season: SeasonResolver,
        rankings: RaceRankingsResolver,
        races: RacesResolver
      },
      children: [{
        path: '',
        component: SeasonComponent
      }, {
        path: 'races/:raceId',
        component: RoutingSlotComponent,
        data: {
          breadcrumb: ({data}) => data.race.model.name
        },
        resolve: {
          race: RaceResolver,
          drivers: DriversResolver
        },
        children: [{
          path: '',
          component: RaceComponent
        }, {
          path: 'rankings/:rankingId',
          component: RoutingSlotComponent,
          data: {
            breadcrumb: ({data}) => data.ranking.model.name
          },
          resolve: {
            ranking: RaceRankingResolver
          },
          children: [{
            path: '',
            component: RaceRankingComponent,
          }]
        }],
      }, {
        path: 'results',
        component: SeasonResultsComponent,
        data: {
          breadcrumb: 'Season results'
        }
      }]
    }]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(rankingRoutes)],
  exports: [RouterModule]
})
export class SeasonRoutingModule {
}
