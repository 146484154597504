import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, take, shareReplay, find } from 'rxjs/operators';
import { AsyncCollectionElement } from '../../app-commons/operators/async-collection-element';

@Injectable({
  providedIn: 'root'
})
export class SeasonResolver implements Resolve<AsyncCollectionElement> {

  constructor(
    private router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AsyncCollectionElement> {
    return from<Observable<AsyncCollectionElement>>(route.parent.data.seasons).pipe(
      find(season => season.model.year === +route.params.seasonYear),
      map(season => {
        if (season) {
          return season;
        } else {
          this.router.navigate(['/']);
          return null;
        }
      }),
      take(1),
      shareReplay(1)
    );
  }
}
