<div class="row">
	<div class="col-4">
		<h4>Drivers:</h4>
		<mc-drivers></mc-drivers>
	</div>
	<div class="col-4">
		<h4>Append GateReadings (CSV):</h4>
		<mc-gate-readings (updated)="appendGateReadings($event)"></mc-gate-readings>
	</div>
	<div class="col-4">
		<h4>Loaded gate readings:</h4>
		<mc-gate-readings-list [gateReadings]="gateReadings"></mc-gate-readings-list>
		<button class="btn btn-primary" (click)="clearGateReadings()">Clear</button>
	</div>
</div>

<div class="row">
	<div class="col-12">
		<h4>Standings</h4>
		<mc-standings-moderation
			(standingsConfigChanged)="standingsConfig = $event"
			(resultsConfigChanged)="resultsConfig = $event"
		></mc-standings-moderation>
		<mc-standings
			[standingsConfig]="standingsConfig"
			[resultsConfig]="resultsConfig"
		></mc-standings>
	</div>
</div>
