import { BehaviorSubject, Observable } from 'rxjs/index';
import { map, distinct, filter, find, mergeMap, switchMap, shareReplay } from 'rxjs/operators';
import { AsyncCollectionElement } from './async-collection-element';

export function cache(
  project?: (value: any) => Observable<any>,
  keySelector?: null | undefined | string | ((value: any) => any),
) {
  return (stream$: Observable<any>) => {
    // console.log(100, 'New cache stream.', project);
    return stream$.pipe(
      // filter(value => !!value),
      distinct(keySelector ? getKeyBySelector(keySelector) : undefined),
      mergeMap(value => (project || ((item: any): Observable<any> => item))(value).pipe(
        map(source => [getKeyBySelector(keySelector)(value), source])
      )),
      shareReplay()
    );
  };
}

export function cachedMergeMap(
  project?: (value: any) => Observable<any>,
  keySelector?: null | undefined | string | ((value: any) => any),
) {
  return (stream$: Observable<any>) => {
    const cacheStream$ = stream$.pipe(
      cache(project, keySelector)
    );

    return stream$.pipe(
      mergeMap(value => cacheStream$.pipe(
        find(([key, source]) => key === getKeyBySelector(keySelector)(value)),
        map(([key, source]) => source)
      )),
      shareReplay(1)
    );
  };
}

export function cachedSwitchMap(
  project?: (value: any) => Observable<any>,
  keySelector?: null | undefined | string | ((value: any) => any),
) {
  return (stream$: Observable<any>) => {
    const cacheStream$ = stream$.pipe(
      cache(project, keySelector)
    );

    return stream$.pipe(
      switchMap(value => cacheStream$.pipe(
        find(([key, source]) => key === getKeyBySelector(keySelector)(value)),
        map(([key, source]) => source)
      )),
      shareReplay(1)
    );
  };
}

export function cachedCollectionMap(
  collection$: (collectionId: any) => Observable<any>,
  elementMapper: (element: any) => AsyncCollectionElement
) {
  // console.log(101, 'New collection cache: ', elementMapper);
  return (collectionId$: Observable<any>) => collectionId$.pipe(
    cache(collectionId => collection$(collectionId).pipe(
      map(collection => new BehaviorSubject(collection.map(elementMapper))),
    )),
    mergeMap(([collectionId, elements$]) => elements$),
    shareReplay()
  );
}

function getKeyBySelector(keySelector: null | undefined | string | ((value: any) => any)): any {
  if (!keySelector) {
    return (value: any): any => value;
  } else if (typeof keySelector === 'string') {
    return (value: any): any => value[keySelector];
  } else {
    return keySelector;
  }
}
