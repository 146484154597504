<form novalidate>
	<div>
		<label>
			<input type="checkbox" (change)="showCsvExport = $event.target.checked" />
			{{readingTimesForApp.length}} reading times.
		</label>

		<div *ngIf="showCsvExport">
			<hr />
			<div *ngFor="let readingTimeForApp of readingTimesForApp">
				{{readingTimeForApp | json}},
			</div>
		</div>
	</div>
</form>
