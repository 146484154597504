import { Component, Input, OnInit } from '@angular/core';
import { GateReading } from '../gate-readings/gate-reading';
import { Tag } from '../tags/tag';
import { ReadingTimeForApp } from './reading-time-for-app';
import { ReadingTimesService } from '../reading-times/reading-times.service';
import { ReadingTime } from '../reading-times/reading-time';
import { TagsService } from '../tags/tags.service';

@Component({
  selector: 'mc-reading-times-for-app',
  templateUrl: './reading-times-for-app.component.html',
  styleUrls: ['./reading-times-for-app.component.scss']
})
export class ReadingTimesForAppComponent implements OnInit {

  readingTimesForApp: ReadingTimeForApp[] = [];
  showCsvExport = false;

  @Input() set gateReadings(gateReadings: GateReading[]) {
    this.readingTimesForApp = this.fromGateReadings(gateReadings);
  }

  @Input() tags: Tag[] = [];


  constructor(
    private tagsService: TagsService,
    private readingTimesService: ReadingTimesService
  ) {
  }

  ngOnInit() {
  }

  fromGateReadings(gateReadings: GateReading[]): ReadingTimeForApp[] {
    const t0 = new Date('1900-01-01 00:00:00.000Z').getTime();
    return this.readingTimesService.fromGateReadings(gateReadings)
      .map((readingTime: ReadingTime) => {
        const tag = this.tagsService.findByTagId(readingTime.Zawodnik_Id);
        return {
          Id: readingTime.Id,
          Prefer_Start_number: tag.Nr_startowy,
          Time: (new Date(readingTime.Czas + 'Z').getTime()) - t0,
          Lap: readingTime.Lap
        } as ReadingTimeForApp;
      });
  }
}
