import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, shareReplay, switchMap } from 'rxjs/operators';
import { Ranking } from './ranking';
import { MocksService } from '../../gepard/mocks/mocks.service';
import { Season } from '../season/season';
import { SeasonsService } from '../seasons.service';

@Injectable({
  providedIn: 'root'
})
export class RankingsService {

  rankings: Ranking[] = [];

  rankings$: Observable<Ranking[]> = this.seasonsService.season$.pipe(
    switchMap((season: Season) => season ? this.requestRankings(season.year) : []),
    shareReplay(1)
  );

  constructor(
    private mocks: MocksService,
    private seasonsService: SeasonsService
  ) {
  }

  requestRankings(seasonYear): Observable<Ranking[]> {
    return this.mocks.get('rankings/mc' + seasonYear + '.json').pipe(
      // tap(a => console.log('Downloading races list:', a)),
      map(json => JSON.parse(json))
    );
  }
}
