import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mc-layout-content',
  templateUrl: './layout-content.component.html',
  styleUrls: ['./layout-content.component.scss']
})
export class LayoutContentComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
