import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MocksService } from '../../gepard/mocks/mocks.service';
import { League } from './league';

@Injectable({
  providedIn: 'root'
})
export class LeaguesService {

  constructor(
    private mocks: MocksService
  ) {
  }

  request(environmentId: string): Observable<League[]> {
    return this.mocks.get('leagues/' + environmentId + '.json').pipe(
      // tap(leagues => console.log('Downloading leagues league list:', leagues)),
      map(json => JSON.parse(json))
    );
  }
}
