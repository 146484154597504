import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seasonPoints'
})
export class SeasonPointsPipe implements PipeTransform {

  static readonly SEASON_POINTS_MAP = [30, 27, 25, 23, 21, 19, 17, 15, 13, 12, 9, 7, 5, 3, 2, 1];

  transform(position: number, multiplier?: number): any {
    multiplier = multiplier || 1;
    if (position <= SeasonPointsPipe.SEASON_POINTS_MAP.length) {
      return SeasonPointsPipe.SEASON_POINTS_MAP[position - 1] * multiplier;
    } else {
      return multiplier;
    }
  }
}
